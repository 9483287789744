export function register_horizontal_scrolling(element, scroll_value = 20, portrait_only = false) {
    let last_scroll_top = 0;

    const scroll_listener = event => {
        if (portrait_only && !window.matchMedia('(orientation: portrait)').matches)
            return;

        const st = window.pageYOffset || document.documentElement.scrollTop;
        element.scrollLeft += (st > last_scroll_top ? scroll_value: -scroll_value);
        last_scroll_top = st <= 0 ? 0 : st;
    }

    addEventListener('scroll', scroll_listener);
}
