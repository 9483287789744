import {
    css,
    html,
    LitElement
} from 'lit';
export default class AltShiftBox extends LitElement {
    static get styles() {
        return [css`:host{--offset-top:var(--standard-box-offset-top);--offset-left:var(--standard-box-offset-left);--inner-border:var(--border-width) solid var(--border-color);--filler-top-height:calc(100% - var(--offset-top));--filler-bottom-height:var(--offset-top)}*,*::before,*::after{box-sizing:border-box}slot{display:block}.outer{display:flex;border-top:var(--border-width) solid var(--border-color);width:100%}slot[name="inner"]{margin-top:var(--offset-top);border:var(--inner-border);width:100%}.filler{display:flex;flex-direction:column;min-height:100%;width:var(--offset-left)}.filler__top{border-left:var(--border-width) solid var(--border-color);height:var(--filler-top-height)}.filler__bottom{height:var(--filler-bottom-height);width:100%;stroke-width:var(--border-width);stroke:var(--border-color)}`];
    }
    render() {
        return html`<div class="outer"><div class="filler"><div class="filler__top"></div><svg class="filler__bottom"><line x1="0" y1="0" x2="100%" y2="100%"/></svg></div><slot name="inner"></slot></div>`;
    }
}
customElements.define('altshift-box', AltShiftBox);