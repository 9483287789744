import {register_horizontal_scrolling} from "./common_functions.js";
import AltShiftHeader from "./custom_elements/altshift_header";
import AltShiftFooter from "./custom_elements/altshift_footer";
import AltShiftHoverTextBox from "./custom_elements/altshift_hover_text_box";
import AltShiftButton from "./custom_elements/altshift_button";
import ContactUs from "./custom_elements/contact_us";
import VerticalScrollHider from "./custom_elements/vertical_scroll_hider";
import AltShiftServicesTable from "./custom_elements/altshift_services_table";
import AltShiftCustomersTable from "./custom_elements/altshift_customers_table";
import CustomerQuote from "./custom_elements/customer_quote";
addEventListener('DOMContentLoaded', () => {
    register_horizontal_scrolling(document.querySelector('.scroll-text-section'), 10);
    document.querySelectorAll('altshift-hover-textbox').forEach(box => box.classList.toggle('altshift-hover-textbox__active'));
});
