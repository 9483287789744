import {
    css,
    html
} from 'lit';
import AltShiftBox from './altshift_box';
export default class AltShiftHoverBox extends AltShiftBox {
    static get styles() {
        const style = css`:host{--ease-time:0.3s;display:inline-block;width:max-content;height:max-content}.filler{display:inline-flex}@media not (prefers-reduced-motion){.filler{transition:width ease var(--ease-time)}}.outer{display:inline-flex;background-color:var(--main-color);width:fit-content}@media not (prefers-reduced-motion){.outer:hover>[name="inner"]{margin-top:calc(-1 * var(--border-width))}.outer:hover>.filler{width:0}}slot[name="inner"]{display:inline-flex;width:unset}@media not (prefers-reduced-motion){slot[name="inner"]{transition:margin-top ease var(--ease-time)}}.compensate-left{display:inline-flex;width:0}@media not (prefers-reduced-motion){.compensate-left{transition:width ease var(--ease-time)}}.compensate-bottom{display:flex;width:100%}slot[name="compensate-bottom__space"]{height:0;width:0}@media not (prefers-reduced-motion){slot[name="compensate-bottom__space"]{transition:height ease var(--ease-time)}}slot[name="compensate-bottom__box"]{height:0;width:0;stroke-width:var(--border-width);stroke:var(--border-color)}@media not (prefers-reduced-motion){slot[name="compensate-bottom__box"]{transition:width ease var(--ease-time),height ease var(--ease-time)}}@media not (prefers-reduced-motion){.outer:hover~.compensate-left{width:var(--offset-left)}.outer:hover~.compensate-bottom>slot[name="compensate-bottom__box"]{height:calc(var(--offset-top) + var(--border-width));width:var(--offset-left)}.outer:hover~.compensate-bottom>slot[name="compensate-bottom__space"]{height:calc(var(--offset-top) + var(--border-width));width:100%}}`;
        return [
            ...AltShiftBox.styles,
            style
        ];
    }
    render() {
        return html`${ super.render() }<div class="compensate-left"></div><div class="compensate-bottom"><slot name="compensate-bottom__space"></slot><slot name="compensate-bottom__box"></slot></div>`;
    }
}
customElements.define('altshift-hover-box', AltShiftHoverBox);