import {
    LitElement,
    css,
    html
} from 'lit';
import AltShiftBox from './altshift_box';
import AltShiftHoverBox from './altshift_hover_box';
export default class AltShiftHoverTextBox extends LitElement {
    static get styles() {
        const style = css`[slot="inner"]{padding:0.75rem 2rem;color:var(--as-black);background-color:var(--standard-box-color);font-weight:700;font-size:1.125rem;text-align:center;text-transform:uppercase;user-select:none;white-space:nowrap}.outer:hover>[name="inner"]{color:var(--text-color);background-color:var(--complement-color)}`;
        return [
            ...AltShiftBox.styles,
            style
        ];
    }
    static get properties() {
        return { text: { type: String } };
    }
    constructor(text = '') {
        super();
        this.text = text;
    }
    render() {
        return html`<altshift-hover-box><div slot="inner">${ this.text }</div></altshift-hover-box>`;
    }
}
customElements.define('altshift-hover-textbox', AltShiftHoverTextBox);