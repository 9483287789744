import {
    css,
    html,
    render
} from 'lit';
import AltShiftHoverBox from './altshift_hover_box';
export default class AltShiftButton extends AltShiftHoverBox {
    static get styles() {
        const style = css`:host(:hover)>.outer>[name="inner"],a:focus-visible{color:var(--text-color);background-color:var(--complement-color)}.outer{cursor:pointer}[name="inner"]{color:var(--text-color);background-color:var(--main-color);font-weight:700;font-size:1.125rem;text-align:center;text-transform:uppercase;user-select:none;white-space:nowrap}[name="inner"]>*{padding:0.75rem 2rem}a{text-decoration:none;color:var(--text-color)}a:focus-visible{outline:none}`;
        return [
            ...AltShiftHoverBox.styles,
            style
        ];
    }
    static get properties() {
        return {
            text: { type: String },
            link: { type: String }
        };
    }
    constructor(text = '', link = null) {
        super();
        this.text = text;
        this.link = link;
    }
    update(changedProperties) {
        super.update(changedProperties);
        const html_content = this.link !== null ? html`<a href="${ this.link }">${ this.text }</a>` : html`<span>${ this.text }</span>`;
        render(html_content, this.shadowRoot.querySelector('[name="inner"]'));
    }
}
customElements.define('altshift-button', AltShiftButton);