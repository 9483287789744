import {
    css,
    html,
    LitElement
} from 'lit';
export default class VerticalScrollHider extends LitElement {
    static get styles() {
        return css`:host{display:block}`;
    }
    refresh_padding() {
        const scrollable_element = this.shadowRoot.host;
        scrollable_element.style.paddingRight = `${ scrollable_element.offsetWidth - scrollable_element.clientWidth }px`;
    }
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        new MutationObserver((mutations, observer) => {
            this.refresh_padding();
        }).observe(this.shadowRoot.querySelector('slot').assignedElements().at(-1), {
            attributes: false,
            childList: true,
            subtree: false
        });
        this.refresh_padding();
    }
    constructor() {
        super();
        this.addEventListener('slotchange', () => {
            this.refresh_padding();
        });
    }
    render() {
        return html`<div><slot></slot></div>`;
    }
}
customElements.define('vertical-scroll-hider', VerticalScrollHider);