import {
    css,
    html,
    LitElement
} from 'lit';
export default class AltShiftServicesTable extends LitElement {
    static get styles() {
        return css`:host{--new-offset-top:1rem;--title-container-padding:2rem;--title-container-font-size:1.875rem;--title-container-line-height:1.3;--new-filler-top-height:calc(50% - 0.5 * var(--new-offset-top))}@media screen and (max-width:1280px){:host{--new-filler-top-height:calc(100% - var(--new-offset-top))}}[slot="inner"]{display:grid;grid-template-columns:1fr 1fr;grid-auto-rows:1fr;width:100%}@media screen and (max-width:1280px){[slot="inner"]{grid-template-columns:1fr}}.title-container{grid-column:1;grid-row:1/3;padding:var(--title-container-padding);border-top:var(--border-width) solid var(--border-color);border-left:var(--border-width) solid var(--border-color);border-bottom:var(--border-width) solid var(--border-color);font-weight:900;font-size:var(--title-container-font-size);line-height:var(--title-container-line-height)}@media screen and (max-width:1280px){.title-container{border-right:var(--border-width) solid var(--border-color)}}.texts-container{display:contents}@media not screen and (max-width:1280px){.text-container:first-of-type{border-top:var(--border-width) solid var(--border-color)}}.text-container{display:flex;align-items:center;height:100%;padding:0 2rem;box-sizing:border-box;border-right:var(--border-width) solid var(--border-color);border-bottom:var(--border-width) solid var(--border-color);border-left:var(--border-width) solid var(--border-color);grid-column:2;font-weight:700;font-size:1.125rem;line-height:1.4375rem;text-transform:uppercase}@media screen and (max-width:1280px){.text-container{grid-column:1}}altshift-box{--offset-top:var(--new-offset-top);--offset-left:1.25rem;--inner-border:unset;--filler-top-height:var(--new-filler-top-height)}`;
    }
    static get properties() {
        return {
            title: { type: String },
            texts: { type: Array }
        };
    }
    constructor(title = '', texts = null) {
        super();
        this.title = title;
        this.texts = [];
    }
    render() {
        return html`<altshift-box><div slot="inner"><div class="title-container">${ this.title }</div><div class="texts-container">${ this.texts.map(text => html`<div class="text-container">${ text }</div>`) }</div></div></altshift-box>`;
    }
}
customElements.define('altshift-services-table', AltShiftServicesTable);